<template>
  <div>
    <Modal v-model="flag" title="请在空白处签名" :mask-closable="false" @on-cancel="close">
      <div class="sign-box" :id="`${_uid}_sign_box`"></div>
      <div slot="footer">
        <Button @click="clear">清空</Button>
        <Button type="primary" :loading="posting" @click="ok">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      posting: false,
    };
  },
  mounted: function() {
    $(`#${this._uid}_sign_box`).jSignature({
      "background-color": "transparent",
      "decor-color": "transparent",
      width: 488,
      height: 200,
      lineWidth: 2
    });
  },
  methods: {
    open() {
      this.flag = true;
    },
    close() {
      this.clear()
      this.flag = false;
      this.posting = false;
    },
    clear() {
      $(`#${this._uid}_sign_box`).jSignature("reset");
    },
    ok() {
      let datapair = $(`#${this._uid}_sign_box`).jSignature("getData", "image");
      // "data:" + datapair[0] + "," + datapair[1]
      this.posting = true;
      this.$emit("on-ok", datapair);
    }
  }
};
</script>
<style lang="less">
.sign-box {
  border: 1px solid #e8eaec;
  height: 202px;
}
</style>


